/* html{
    background: #778DA9;
    font-family: San Francisco;
} */

.insta :hover{
    filter: grayscale(1);
}
.face :hover{
    filter: grayscale(1);
}
.linked :hover{
    filter: grayscale(1);
}
.git :hover{
    filter: grayscale(1);
}