.links h1{
    color: #bfa15c;
    background-image: linear-gradient(to right, white 50%, #bfa15c 50%);
     background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 100%;
    background-position: 100%;
    transition: background-position 275ms ease;
    font-family: San Francisco;
}
.links h1:hover{
    background-position: 0 100%;
}

.links .active{
    padding-bottom: 12px;
    border-bottom: 3px solid yellow;
}